<template>
  <Form :labelWidth="100" :data="formData">
    <FormItem label="名称">
      <Input
        v-model="formData.targetLocation"
        placeholder="简单描述该运费, 例如奥克兰北岸"
      />
    </FormItem>
    <FormItem label="价格">
      <InputNumber
        v-model="formData.shippingFee"
        placeholder="价格"
        style="width: 150px"
      />
    </FormItem>
  </Form>
</template>

<script>
const initData = () => {
  return {
    targetLocation: "",
    shippingFee: 5,
  }
}

export default {
  name: "ShipmentFeeEditor",
  components: {},
  props: {
    form: {
      type: Object,
      default: () => initData(),
    },
  },
  data() {
    return {
      formData: Object.assign({}, this.form),
    }
  },
  computed: {},
  watch: {
    formData: {
      deep: true,
      handler(newV) {
        this.$emit("update:form", newV)
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
  },
}
</script>

<style></style>
