<template>
  <ContentWrapper :links="topLinks">
    <Alert type="warning">因为涉及到获取地理位置定位问题，暂时关闭后台发布权限</Alert>
    <Form :labelWidth="100" style="padding-right: 100px" :disabled="false">
      <FormItem label="商品图片">
        <div v-if="uploadedImages.length === 0">暂未上传图片</div>
        <div class="uploadedImages" v-else>
          <span class="imgWrapper" v-for="i in uploadedImages" :key="i.path">
            <img v-real-img="i.url" style="width: 80px; height: 80px" />
            <Button class="imgClose" shape="circle" icon="md-close" @click="removeUploadImage(i.path)"></Button>
          </span>
        </div>
      </FormItem>
      <FormItem label="">
        <Upload
          multiple
          :headers="{ 'X-Auth': token }"
          action="/backstage/media/uploadImages"
          name="images"
          :format="['jpg', 'jpeg', 'png']"
          :max-size="1024"
          :before-upload="filesReadyToUpload"
          :on-success="filesUploaded"
          :show-upload-list="false"
        >
          <Button icon="ios-cloud-upload-outline">上传</Button>
        </Upload>
      </FormItem>
      <FormItem label="标题">
        <Input v-model="form.title" placeholder="请输入标题" />
      </FormItem>
      <FormItem label="商品描述">
        <Input type="textarea" :rows="5" v-model="form.content" placeholder="请输入描述" />
      </FormItem>
      <FormItem label="商品类型">
        <RadioGroup v-model="form.itemType">
          <Radio :label="0">全新</Radio>
          <Radio :label="1">二手</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="商品分类">
        <Select v-model="form.categoryName" filterable>
          <Option v-for="item in categoryInList" :value="item.name" :key="item.alias">
            {{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="商品价格">
        <InputNumber
          v-model="form.price"
          placeholder="请输入价格"
          :precision="2"
          :max="10000000"
          :min="0"
          style="min-width: 150px"
        />
      </FormItem>
      <FormItem label="商品数量">
        <InputNumber :max="100000" :min="0" v-model="form.quantity" style="min-width: 150px" placeholder="请输入数量" />
      </FormItem>
      <FormItem label="配送: 自提">
        <i-switch v-model="form.pickUpBySelf" />
      </FormItem>
      <FormItem label="自提地址">
        <Input />
      </FormItem>
      <FormItem label="配送: 物流">
        <i-switch v-model="form.deliveryByExpress" />
      </FormItem>
      <FormItem label="物流计价方式" v-if="form.deliveryByExpress">
        <Select v-model="form.shippingFeeType">
          <Option value="ONE">免运费</Option>
          <Option value="CALC">计算运费</Option>
          <Option value="FIXED">固定运费</Option>
        </Select>
      </FormItem>
      <FormItem label="商品尺寸" v-if="form.shippingFeeType === 'CALC'">
        <Form inline :labelWidth="80">
          <FormItem label="预设">
            <Select v-model="form.sizeAndWeight" @on-change="sizeAndWeightChanged">
              <Option v-for="s in packagePresets" :key="s.code" :value="s.code">
                {{ s.name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="宽度(厘米)">
            <InputNumber :min="0.1" :max="10000" v-model="form.width" placeholder="宽度(厘米)" />
          </FormItem>
          <FormItem label="高度(厘米)">
            <InputNumber :min="0.1" :max="10000" v-model="form.height" placeholder="高度(厘米)" />
          </FormItem>
          <FormItem label="长度(厘米)">
            <InputNumber :min="0.1" :max="10000" v-model="form.length" placeholder="长度(厘米)" />
          </FormItem>
          <FormItem label="重量(克)">
            <InputNumber :min="0.01" :max="1000000" :precision="0" v-model="form.weight" placeholder="重量(克)" />
          </FormItem>
        </Form>
      </FormItem>
      <FormItem label="运费配置" v-if="form.shippingFeeType === 'FIXED'">
        <Button type="primary" @click="showShipmentFeeDialog = true">
          添加
        </Button>
        <Table :data="form.shipmentFees" :columns="shipmentFeesTable" size="small" />
      </FormItem>
      <FormItem label="有效期限">
        <Select v-model="form.term">
          <Option v-for="t in availableTerms" :key="t.term" :label="t.name" :value="t.term">
            {{ t.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem>
        <Button size="large" type="primary" @click="publish">发布</Button>
      </FormItem>
    </Form>
    <Modal v-model="showShipmentFeeDialog" title="添加运费配置" @on-ok="addFeeItem">
      <ShipmentFeeEditor :form.sync="feeItem" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { queryCategories, getAvailableTerms, getPackagePresets, publishItem } from "@/api"
import ShipmentFeeEditor from "@/components/item/ShipmentFeeEditor.vue"

const maxFiles = 9

const initData = () => {
  return {
    title: "",
    content: "",
    itemType: 0,
    price: 0,
    quantity: 1,
    categoryName: "",
    sizeAndWeight: "custom",
    pickUpBySelf: true,
    deliveryByExpress: true,
    shippingFeeType: "ONE",
    shipmentFees: [],
    width: 20,
    height: 20,
    length: 20,
    weight: 1000,
    term: 86400000 * 7,
  }
}

export default {
  name: "ItemPublishPage",
  components: { ContentWrapper, ShipmentFeeEditor },
  data() {
    return {
      topLinks: [{ title: "商品发布", link: this.$route.path }],
      categories: [],
      categoryInList: [],
      availableTerms: [],
      packagePresets: [],
      uploadedImages: [],
      token: sessionStorage.getItem("token"),
      shipmentFeesTable: [
        { title: "展示名称", key: "targetLocation" },
        { title: "物流价格", key: "shippingFee" },
        {
          title: "操作",
          render: ((h, params) => {
            return h(
              "Button",
              {
                on: {
                  click: () => {
                    this.form.shipmentFees = this.form.shipmentFees.filter(
                      item => item.targetLocation != params.row.targetLocation
                    )
                  },
                },
              },
              ["删除"]
            )
          }).bind(this),
        },
      ],
      feeItem: { targetLocation: "", shippingFee: 5 },
      form: {
        title: "",
        content: "",
        itemType: 0,
        price: 0,
        quantity: 1,
        categoryName: "",
        sizeAndWeight: "custom",
        pickUpBySelf: true,
        deliveryByExpress: true,
        shippingFeeType: "ONE",
        shipmentFees: [],
        width: 20,
        height: 20,
        length: 20,
        weight: 1000,
        term: 86400000 * 7,
      },
      showShipmentFeeDialog: false,
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    process(cate) {
      if (cate.children && !cate.isLeaf) {
        cate.children = cate.children.map(item => this.process(item))
      }
      cate.expand = true
      cate.title = cate.name
      this.categoryInList.push({ name: cate.name, alias: cate.alias })
      return cate
    },
    async reload() {
      try {
        this.categoryInList = []
        const root = this.process(await queryCategories())
        this.categories = [root]

        this.availableTerms = await getAvailableTerms()
        const packagePresets = await getPackagePresets()
        packagePresets.push({
          code: "custom",
          name: "Custom",
          width: 200,
          height: 200,
          length: 200,
          weight: 1000,
        })
        this.packagePresets = packagePresets
      } catch (e) {
        this.$Message.error({ background: true, content: e.message })
      }
    },
    filesReadyToUpload() {
      if (1 + this.uploadedImages.length > maxFiles) {
        this.$Message.error(`单个商品最多可以上传${maxFiles}张图片`)
        return false
      }
    },
    filesUploaded(response) {
      if (this.uploadedImages.length + 1 > maxFiles) {
        this.$Message.error(`单个商品最多可以上传${maxFiles}张图片, 多余的图片将被忽略`)
        return
      }
      this.uploadedImages = this.uploadedImages.concat(response.data)
    },
    removeUploadImage(key) {
      this.uploadedImages = this.uploadedImages.filter(image => image.path != key)
    },
    sizeAndWeightChanged(code) {
      const filtered = this.packagePresets.filter(preset => preset.code === code)
      if (!filtered || filtered.length != 1) {
        this.$Message.error("错误, 未知的尺寸预设")
        return
      }
      const [preset] = filtered
      this.form.width = Math.round(preset.width / 10)
      this.form.height = Math.round(preset.height / 10)
      this.form.length = Math.round(preset.length / 10)
      this.form.weight = preset.weight
    },
    addFeeItem() {
      if (this.form.shipmentFees.filter(item => item.targetLocation == this.feeItem.targetLocation).length > 0) {
        this.$Message.error("已经存在相同的名称")
        return
      }
      this.form.shipmentFees.push(Object.assign({}, this.feeItem))
    },
    async publish() {
      try {
        const filteredCates = this.categoryInList.filter(cate => cate.name === this.form.categoryName)
        if (!filteredCates || filteredCates.length != 1) {
          throw new Error("没有选择类型")
        }
        if (this.form.price == 0) {
          throw new Error("没有设置商品价格")
        }

        const item = {}
        item.price = this.form.price * 100
        item.originalPrice = item.price
        item.itemType = this.form.itemType
        item.title = this.form.title
        item.detailContent = this.form.content
        item.medias = this.uploadedImages.map(image => {
          return { path: image.path }
        })
        item.stockQuantity = this.form.quantity
        item.promotion = {}
        item.promotion.term = this.form.term
        item.categoryAlias = filteredCates[0].alias
        item.shipment = { shippingMethods: [] }
        if (this.form.pickUpBySelf) {
          item.shipment.shippingMethods.push("SELF_PICKUP")
        }
        if (this.form.deliveryByExpress) {
          item.shipment.shippingMethods.push("DELIVERY_BY_EXPRESS")
        }
        if (item.shipment.shippingMethods.length < 1) {
          throw new Error("没有选择物流方式")
        }
        item.shipment.shippingFeeType = this.form.shippingFeeType
        if (this.form.shippingFeeType == "ONE") {
          item.shipment.shippingFee = 0
        } else if (this.form.shippingFeeType == "FIXED") {
          item.shipment.shippingFees = this.form.shipmentFees
        } else if (this.form.shippingFeeType == "CALC") {
          item.sizeAndWeight = {}
          item.sizeAndWeight.width = this.form.width * 10
          item.sizeAndWeight.height = this.form.height * 10
          item.sizeAndWeight.length = this.form.length * 10
          item.sizeAndWeight.weight = this.form.weight
        }

        item.status = 1

        const r = await publishItem(item)
        this.$Message.success(`发布成功, 新商品 ID: ${r}`)
        this.form = initData()
        this.uploadedImages = []
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>

<style></style>
